<template>
  <div class="twitter-feed">
    <div class="twiiter-feed__container" v-if="getTwitterData.length !== 0">
      <div
        v-for="tweet in getTwitterData"
        :key="tweet.tweet_details.user_id"
        class="twitter-feed__tweet d-flex mb-3"
      >
        <div class="twitter-feed__tweet-img-wrapper">
          <img
            class="twitter-feed__tweet-img"
            :src="tweet.tweet_details.profile_url"
            :alt="tweet.tweet_details.user_screen_name"
          />
        </div>
        <div class="twitter-feed__tweet-content mx-4">
          <div
            class="
              twitter-feed__tweet-content-user-date
              d-flex
              my-2
              flex-column
            "
          >
            <div class="twitter-feed__tweet-content-user-date__username">
              {{ tweet.tweet_details.user_screen_name }}
            </div>
            <div class="twitter-feed__tweet-content-user-date__date d-flex flex-column flex-wrap">
              <span class="twitter-feed__tweet-content-user-date__date--primary">
                {{ formatDate(tweet.tweet_details.created_at) }}
              </span>
              <span class="twitter-feed__tweet-content-user-date__date--secondary">
                {{ formatDateTimesAgo(tweet.tweet_details.created_at) }}
              </span>
            </div>
          </div>
          <div class="twitter-feed__tweet-content-text my-3">
            {{ tweet.tweet }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  data() {
    return {
      // topic_name: '',
      // count: 0,
    };
  },
  computed: {
    getTwitterData() {
      return this.$store.state.twitter_data;
    },
  },
  methods: {
    formatDateTimesAgo(_date) {
      return moment(_date).fromNow();
    },
    formatDate(_date) {
      return moment(_date).format('MMM Do YYYY');
    },
  },
  created() {
    this.$highway.singleDispatcher('twitterDetails');
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/abstracts/variables";

.twitter-feed {
  &__tweet {
    &-img {
      border-radius: 50%;
    }

    &-content {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      &-user-date {
        &__username {
          font-weight: 600;
        }

        &__date {
          font-weight: 200;
          font-style: italic;

          &--primary {
            font-size: .8rem;
          }
          &--secondary {
            font-size: .8rem;
            font-weight: 400;
          }
        }
      }
    }
  }
}
</style>
